import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const ProtectedRouteComponent = ({ token, ...rest }) => {
  return token ? <Route {...rest} /> : <Redirect to="/login" />
}

const mapStateToProps = ({ user }) => ({
  token: user.token,
})

export const ProtectedRoute = connect(mapStateToProps)(ProtectedRouteComponent)
