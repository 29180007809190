export const IP_PAGE_TYPES = {
  IP_CHECK_REQUEST: 'IP_PAGE_TYPES.IP_CHECK_REQUEST',
  IP_CHECK_SUCCESS: 'IP_PAGE_TYPES.IP_CHECK_SUCCESS',
  IP_CHECK_FAILED: 'IP_PAGE_TYPES.IP_CHECK_FAILED',
  IP_UNBLOCK_REQUEST: 'IP_PAGE_TYPES.IP_UNBLOCK_REQUEST',
  IP_UNBLOCK_SUCCESS: 'IP_PAGE_TYPES.IP_UNBLOCK_SUCCESS',
  IP_UNBLOCK_FAILED: 'IP_PAGE_TYPES.IP_UNBLOCK_FAILED',
  RATE_LIMIT_REQUEST:'IP_PAGE_TYPES.RATE_LIMIT_REQUEST',
  RATE_LIMIT_SUCCES:'IP_PAGE_TYPES.RATE_LIMIT_SUCCES',
  RATE_LIMIT_FAIL:'IP_PAGE_TYPES.RATE_LIMIT_FAIL',
}
