export const DELETE_USER_TYPES = {
  DELETE_USER_REQUEST: 'DELETE_USER_TYPES.DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_TYPES.DELETE_USER_SUCCESS',
  DELETE_USER_FAIL: 'DELETE_USER_TYPES.DELETE_USER_FAIL',
}

export const USER_UPDATE_TYPES = {
  UPDATE_USER_REQUEST: 'USER_UPDATE_TYPES.UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'USER_UPDATE_TYPES.UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL: 'USER_UPDATE_TYPES.UPDATE_USER_FAIL',
}
