import { connect } from 'react-redux'
import { AddNewUserPage as Self } from './AddNewUser'
import { setLoading } from '../../Layout/Layout.actions'
import { AddNewUserAction } from './AddNewUser.actions'

const mapStateToProps = ({ user }) => ({
  loginData: user.loginData,
  userRole: user.verifiedData.role,
})

const mapDispatchToProps = {
  setLoading,
  AddNewUserAction,
}

export const AddNewUser = connect(mapStateToProps, mapDispatchToProps)(Self)
