import { EMAIL_CHECK_PAGE_TYPES } from './EmailCheckPage.types'
const initialState = {
  emailCheckData: null,
  emailCheckError: null,
  emailCheckLoading: false,
}
export const emailCheckPage = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_CHECK_PAGE_TYPES.EMAIL_CHECK_REQUEST:
      return {
        ...state,
        emailCheckLoading: true,
      }
    case EMAIL_CHECK_PAGE_TYPES.EMAIL_CHECK_SUCCESS:
      return {
        ...state,
        emailCheckLoading: false,
        emailCheckData: action.payload,
      }
    case EMAIL_CHECK_PAGE_TYPES.EMAIL_CHECK_FAILED:
      return {
        ...state,
        emailCheckError: action.error,
      }
      default:
        return state
  }
}
