import { connect } from 'react-redux'
import { IpCheckByEmail as Self } from './IpCheckByEmail'
import { setLoading } from '../../Layout/Layout.actions'
import { ipCheck } from './IpCheckByEmail.actions'

const mapStateToProps = ({ ipCheckByEmail, user }) => ({
  ipCheckByEmail: ipCheckByEmail.ipCheckByEmail,
  ipCheckByEmailLoading: ipCheckByEmail.ipCheckByEmailLoading,
  ipCheckByEmailData: ipCheckByEmail.ipCheckByEmailData,
  hostName: user.hostName,
})

const mapDispatchToProps = {
  setLoading,
  ipCheck,
}

export const IpCheckByEmail = connect(mapStateToProps, mapDispatchToProps)(Self)
