import styled from 'styled-components'
import { Input } from 'antd'

export const EmailConfigurationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 100px;
  gap: 10px;
`

export const StyledInput = styled(Input)`
  width: 250px;
  margin-top: 10px;

  @media (min-width: 768px) {
    width: 400px;
  }
  @media (min-width: 1280px) {
    width: 500px;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.div`
  width: 100%;
  font-size: 20px;
`
