import { connect } from 'react-redux'

import { LeftSideBar as Self } from './LeftSideBar'
import { logout } from '../../../../common/user/user.actions'

const mapStateToProps = ({ user }) => ({
  getMeData: user.getMeData,
})

const mapDispatchToProps = {
  logout,
}

export const LeftSideBar = connect(mapStateToProps, mapDispatchToProps)(Self)
