import React from 'react'
import { Button, Input } from 'antd'
import { Formik } from 'formik'
import { Layout } from '../../Layout'
import * as S from './EmailConfiguration.styles'
import { initialValues, validationSchema } from './EmailConfiguration.config'

export const EmailConfiguration = () => {
  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={validationSchema}
      >
        {({
          errors,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          dirty,
        }) => (
          <div>
            <S.EmailConfigurationContainer>
              <S.InputWrapper>
                <S.Label>Email</S.Label>
                <S.StyledInput
                  placeholder="pogos.pogosyan@gmail.com"
                  type="email"
                  name="email"
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </S.InputWrapper>
              {errors.email && <p className="error">{errors.email}</p>}
              <Button
                htmlType="submit"
                disabled={errors.email || !dirty}
                onClick={handleSubmit}
              >
                Check
              </Button>
              {values.email && values.email.includes('@') && (
                <>
                  <h2 style={{ marginTop: '50px', marginBottom: '50px' }}>
                    <strong>
                      If MX is on our servers, then the configuration is as
                      follows:
                    </strong>
                  </h2>
                  <div style={{ display: 'flex', gap: '60px' }}>
                    <div>
                      <h3>IMAP</h3>
                      <p>
                        hostname:{' '}
                        <strong>mail.{values.email.split('@')[1]}</strong>
                      </p>
                      <p>
                        username: <strong>{values.email}</strong>
                      </p>
                      <p>
                        password: <strong>Your password</strong>
                      </p>
                      <p>
                        port: <strong>993 (143 on Windows 7)</strong>
                      </p>
                      <p>
                        SSL: <strong>on (off on Windows 7)</strong>
                      </p>
                    </div>
                    <div>
                      <h3>POP3</h3>
                      <p>
                        hostname:{' '}
                        <strong>mail.{values.email.split('@')[1]}</strong>
                      </p>
                      <p>
                        username: <strong>{values.email}</strong>
                      </p>
                      <p>
                        password: <strong>Your password</strong>
                      </p>
                      <p>
                        port: <strong>995 (110 on Windows 7)</strong>
                      </p>
                      <p>
                        SSL: <strong>on (off on Windows 7)</strong>
                      </p>
                    </div>
                    <div>
                      <h3>SMTP</h3>
                      <p>
                        hostname:{' '}
                        <strong>mail.{values.email.split('@')[1]}</strong>
                      </p>
                      <p>
                        username: <strong>{values.email}</strong>
                      </p>
                      <p>
                        password: <strong>Your password</strong>
                      </p>
                      <p>
                        port: <strong>465</strong>
                      </p>
                      <p>
                        TLS (Secure): <strong>on</strong>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </S.EmailConfigurationContainer>
          </div>
        )}
      </Formik>
    </Layout>
  )
}
