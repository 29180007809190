import styled from 'styled-components'
import { Input } from 'antd'

export const WebsiteCheckConatiner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
  gap: 20px;
  flex-direction: column;
`

export const StyledInput = styled.input`
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  border-radius: 2;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  width: 150px;
  outline: none;
  @media (min-width: 768px) {
    width: 250px;
  }
  @media (min-width: 1280px) {
    width: 250px;
  }
  :hover {
    border: 1px solid #1890ff;
    cursor: pointer;
  }
`
export const SizeInput = styled(Input)`
  width: 150px;
  // margin-left: 20px;
  outline: none;
  @media (min-width: 768px) {
    width: 250px;
  }
  @media (min-width: 1280px) {
    width: 250px;
  }
  :hover {
    border: 1px solid #1890ff;
    cursor: pointer;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  .Checked{
    cursor: not-allowed;
    :hover {
      border: 1px solid #d9d9d9;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  .button {
    margin-right: 70px;
  }
  .buttonAdd {
    margin-left: 90px;
  }
`
export const DomainInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
