import { DELETE_USER_TYPES, USER_UPDATE_TYPES } from './DeleteUserPage.types'

const initialState = {
  deleteUserLoading: false,
  deleteUserError: [],
  deleteUserData: [],
  makeUserLoading: false,
  makeUserError: [],
  makeUserData: [],
  makeModeratorLoading: false,
  makeModeratorError: [],
  makeModeratorData: [],
  makeAdminLoading: false,
  makeAdminError: [],
  makeAdminData: [],
}

export const deleteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_USER_TYPES.DELETE_USER_REQUEST:
      return {
        ...state,
        deleteUserLoading: true,
        deleteUserError: null,
        deleteUserData: [],
      }
    case DELETE_USER_TYPES.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserError: null,
        deleteUserData: action.payload,
      }
    case DELETE_USER_TYPES.DELETE_USER_FAIL:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserError: action.error,
        deleteUserData: [],
      }
    default:
      return state
  }
}
