import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { Layout } from '../../Layout'
import * as S from './IpPage.styles'
import { initialValues, validationsSchema } from './IpPage.config'
import { Button } from 'antd'
import { logDOM } from '@testing-library/react'

export const IpPage = ({
  ipCheckData,
  ipCheckLoading,
  ipUnblockData,
  ipUnblockLoading,
  checkIpAddress,
  unblockIpAddress,
  hostName,
  rateLimitAction,
  rateLimitLoading,
  rateLimitData,
}) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(false)
  }, [ipCheckData, ipUnblockData])

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={validationsSchema}
      >
        {({
          errors,
          values,
          isValid,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <div>
            <S.IpPageContainer>
              <S.Form>
                <S.InputWrapper>
                  <S.Label>Ip address</S.Label>
                  <S.StyledInput
                    className="searchInput"
                    placeholder="8.8.8.8"
                    name="ipAddress"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ipAddress}
                  />
                </S.InputWrapper>
                {errors.ipAddress && (
                  <p className="error">{errors.ipAddress}</p>
                )}
                <S.Buttons>
                  {
                    <Button
                      disabled={ipCheckLoading || ipUnblockLoading}
                      loading={ipCheckLoading}
                      onClick={() => {
                        checkIpAddress(
                          values.ipAddress,
                          `ext${hostName}.host.am`
                        )
                      }}
                    >
                      Check
                    </Button>
                  }
                  <Button
                    disabled={ipCheckLoading || ipUnblockLoading}
                    loading={ipUnblockLoading}
                    onClick={() => {
                      unblockIpAddress(
                        values.ipAddress,
                        `ext${hostName}.host.am`
                      )
                    }}
                  >
                    Unblock
                  </Button>
                  <Button
                    disabled={
                      rateLimitLoading || ipCheckLoading || ipUnblockLoading
                    }
                    loading={rateLimitLoading}
                    onClick={() => {
                      rateLimitAction(
                        values.ipAddress,
                        `ext${hostName}.host.am`
                      )
                    }}
                  >
                    RateLimith
                  </Button>
                </S.Buttons>
              </S.Form>
              <code>
                {ipCheckData && !ipCheckLoading && (
                  <S.CodeArea isShow={show}>
                    <div>
                      {ipCheckData.data.rawData.split('\\n').map((i, index) => {
                        return <div key={index}>{i}</div>
                      })}
                    </div>
                  </S.CodeArea>
                )}

                {ipUnblockData && !ipUnblockLoading && (
                  <S.CodeArea isShow={show}>
                    <div>
                      {ipUnblockData.data.rawData
                        .split('\n')
                        .map((i, index) => {
                          return <div key={index}>{i}</div>
                        })}
                    </div>
                  </S.CodeArea>
                )}
                {rateLimitData && !rateLimitLoading && (
                  <S.CodeArea isShow={show}>
                    <div>
                      {rateLimitData.data.rawData
                        .split('\n')
                        .map((i, index) => {
                          return <div key={index}>{i}</div>
                        })}
                    </div>
                  </S.CodeArea>
                )}
              </code>
            </S.IpPageContainer>
          </div>
        )}
      </Formik>
    </Layout>
  )
}
