import React, { useCallback, useState } from 'react'
import * as S from './LoginPage.styles'
import { Formik, Form } from 'formik'
import { Input, Button } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { initialValues, validationSchema } from './LoginPage.config'

export const LoginPage = ({ login, loginLoading, history, signInErrors }) => {
  const handleEnter = useCallback((e, submitForm) => {
    if (e.key === 'Enter') {
      submitForm()
    }
  }, [])

  const [passwordVisible, setPasswordVisible] = useState(false)

  return (
    <S.LoginPageContainer>
      <Formik
        onSubmit={(values) =>
          login(values, {
            onSuccess: () => history.push('/main'),
            onVerified: () => history.push('/verify'),
            onTotp: () => history.push('/qr-code'),
          })
        }
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldTouched,
          submitForm,
          errors,
          touched,
        }) => (
          <S.StyledForm onKeyPress={() => handleEnter(submitForm)}>
            <S.SignInContainer>Sign in</S.SignInContainer>
            <Input
              placeholder="Email"
              name="email"
              onChange={(e) => {
                setFieldTouched(e.target.name)
                handleChange(e)
              }}
              value={values.email}
              type="email"
            />
            {touched.email && !!errors.email && (
              <S.ErrorMessage>{errors.email}</S.ErrorMessage>
            )}
            <Input.Password
              value={values.password}
              onChange={(e) => {
                setFieldTouched(e.target.name)
                handleChange(e)
              }}
              name="password"
              placeholder="Password"
              type="password"
              iconRender={(passwordVisible) =>
                passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            {signInErrors
              ? signInErrors.map((error) => (
                  <S.ErrorMessage>{error}</S.ErrorMessage>
                ))
              : null}
            {touched.password && !!errors.password && (
              <S.ErrorMessage>{errors.password}</S.ErrorMessage>
            )}
            <S.ButtonContainer>
              <Button
                htmlType="submit"
                onClick={handleSubmit}
                disabled={loginLoading}
              >
                {loginLoading ? 'Loading...' : 'Log in'}
              </Button>
            </S.ButtonContainer>
          </S.StyledForm>
        )}
      </Formik>
    </S.LoginPageContainer>
  )
}
