import { FetchApi } from '../../../utils/FetchApi'
import { IP_CHECK_BY_EMAIL } from './IpCheckByEmail.types'

export const ipCheck = (values, hostName) => async (dispatch) => {
  try {
    dispatch({
      type: IP_CHECK_BY_EMAIL.IP_CHECK_BY_EMAIL_REQUEST,
    })
    const checkEmail = await FetchApi.post('commands', {
      command: 'findIpsByEmail',
      arg1: values.email,
      host: hostName,
    })
    dispatch({
      type: IP_CHECK_BY_EMAIL.IP_CHECK_BY_EMAIL_SUCCESS,
      payload: checkEmail,
    })
  } catch (e) {
    dispatch({
      type: IP_CHECK_BY_EMAIL.IP_CHECK_BY_EMAIL_FAILED,
      payload: e,
    })
  }
}
