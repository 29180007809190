import { FetchApi } from '../../../utils/FetchApi'
import { IP_PAGE_TYPES } from './IpPage.types'

export const checkIpAddress = (value, hostName) => async (dispatch) => {
  try {
    dispatch({
      type: IP_PAGE_TYPES.IP_CHECK_REQUEST,
    })

    const cpHulkData = await FetchApi.post('commands', {
      command: 'checkCphulk',
      arg1: value,
      host: hostName,
    })

    dispatch({
      type: IP_PAGE_TYPES.IP_CHECK_SUCCESS,
      payload: cpHulkData,
    })
  } catch (e) {
    dispatch({
      type: IP_PAGE_TYPES.IP_CHECK_FAILED,
      payload: e,
    })
  }
}

export const unblockIpAddress = (value, hostName) => async (dispatch) => {
  try {
    dispatch({
      type: IP_PAGE_TYPES.IP_UNBLOCK_REQUEST,
    })

    const unblockCsfData = await FetchApi.post('commands', {
      command: 'unblockCsf',
      arg1: value,
      host: hostName,
    })
    dispatch({
      type: IP_PAGE_TYPES.IP_UNBLOCK_SUCCESS,
      payload: unblockCsfData,
    })
  } catch (e) {
    dispatch({
      type: IP_PAGE_TYPES.IP_UNBLOCK_FAILED,
      payload: e.message,
    })
  }
}
export const rateLimitAction = (value, hostName) => async (dispatch) => {
  try {
    dispatch({
      type: IP_PAGE_TYPES.RATE_LIMIT_REQUEST,
    })

    const rateLimitData = await FetchApi.post('commands', {
      command: 'rateLimit',
      arg1: value,
      host: hostName,
    })
    dispatch({
      type: IP_PAGE_TYPES.RATE_LIMIT_SUCCES,
      payload: rateLimitData,
    })
  } catch (e) {
    dispatch({
      type: IP_PAGE_TYPES.RATE_LIMIT_FAIL,
      payload: e.message,
    })
  }
}
