import { EDIT_QUOTA_TYPES } from './EditQuota.types'
const initialState = {
  editQuotaData: null,
  editQuotaLoading: false,
  editQuotaError: null,
  currentQuotaData: '',
  currentQuotaLoading: false,
  currentQuotaError: null,
}
export const editQuota = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_QUOTA_TYPES.EDIT_QUOTA_REQUEST:
      return {
        ...state,
        editQuotaLoading: true,
      }
    case EDIT_QUOTA_TYPES.EDIT_QUOTA_SUCCESS:
      return {
        ...state,
        editQuotaLoading: false,
        editQuotaData: action.payload,
      }
    case EDIT_QUOTA_TYPES.EDIT_QUOTA_FAILED:
      return {
        ...state,
        editQuotaError: action.error,
      }
    default:
      return state
  }
}
export const currentQuota = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_QUOTA_TYPES.CURRENT_QUOTA_REQUEST:
      return {
        ...state,
        currentQuotaLoading: true,
      }
    case EDIT_QUOTA_TYPES.CURRENT_QUOTA_SUCCESS:
      return {
        ...state,
        currentQuotaLoading: false,
        currentQuotaData: action.payload,
      }
    case EDIT_QUOTA_TYPES.CURRENT_QUOTA_FAILED:
      return {
        ...state,
        currentQuotaError: action.error,
        currentQuotaLoading: false,
      }
    default:
      return state
  }
}
