import styled from 'styled-components/macro'

export const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`

export const StyledInput = styled.input`
  width: 200px;
  height: 40px;
  border: 1px solid black;
  border-radius: 3px;
  padding: 5px;
  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
    width: 500px;
  }
`

export const DeleteButton = styled.button`
  width: 70px;
  height: 40px;
  background-color: #d4888d;
  color: black;
  outline: none;
  border: 1px solid black;
  border-radius: 3px;
  margin-left: 20px;
  cursor: pointer;
`

export const ErrorMessage = styled.p`
  color: red;
`
export const SuccessMessage = styled.p`
  color: green;
`
export const Table = styled.table`
  width: 800px;
`
export const Thead = styled.thead`
  display: flex;
  justify-content: space-between;
`
export const Th = styled.th`
  width: 100px;
  height: 50px;
`

export const Tr = styled.tr`
  /* background-color: #b5baba; */
  width: 100px;
`
export const Tbody = styled.tbody``

export const Td = styled.td`
  /* background-color: red; */
  height: 40px;
  padding: 15px;
  white-space: normal;
`
export const Admin = styled.p`
  color: red;
`
export const User = styled.p`
  color: black;
`
