import styled from 'styled-components'
import { Input } from 'antd'

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const StyledInput = styled(Input)`
  width: 200px;

  @media (min-width: 768px) {
  }
  @media (min-width: 1280px) {
    width: 500px;
  }
`
export const SignInContainer = styled.div`
  font-size: 20px;
`

export const AddNewUserContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 100px;
`
export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  color: black;
`
