import { USER_NAME_TYPES } from './BackUp.types'
import { FetchApi } from '../../../utils/FetchApi'

export const hetznerCheck = (username, backupHost) => async (dispatch) => {
  try {
    dispatch({
      type: USER_NAME_TYPES.USER_NAME_REQUEST,
    })
    const checkBackUp = await FetchApi.post('commands', {
      command: 'hetznerCheck',
      arg1: username,
      arg2: backupHost,
      host: 'ext45.host.am',
    })
    dispatch({
      type: USER_NAME_TYPES.USER_NAME_SUCCESS,
      payload: checkBackUp,
    })
  } catch (e) {
    dispatch({
      type: USER_NAME_TYPES.USER_NAME_FAIL,
      payload: e,
    })
  }
}

export const restoreCheck = (username, backupHost) => async (dispatch) => {
  try {
    dispatch({
      type: USER_NAME_TYPES.RESTORE_REQUEST,
    })
    const checkRestore = await FetchApi.post('commands', {
      command: 'restoreBk',
      arg1: username,
      arg2: backupHost,
      host: `ext${backupHost}.host.am`,
    })
    dispatch({
      type: USER_NAME_TYPES.RESTORE_SUCCESS,
      payload: checkRestore,
    })
  } catch (e) {
    dispatch({
      type: USER_NAME_TYPES.RESTORE_FAIL,
      payload: e,
    })
  }
}
