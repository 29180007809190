import Collapse from 'react-css-collapse'
import styled from 'styled-components/macro'
import Sider from 'antd/lib/layout/Sider'

export const ToggleBtn = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #001529;
  font-size: 15px;
  position: absolute;
  top: 15px;
  left: 5px;
  display: block;
  z-index: 2;

  @media (min-width: 768px) {
    display: none;
  }
`

export const StyledSider = styled(Sider)`
  padding-top: 65px;
  z-index: 1;
  position: block;
  max-width: unset !important;
  width: unset !important;
  height: 100%;
  background-color: #af69ed;
`

export const StyledCollapse = styled(Collapse)`
  width: ${(p) => (p.isOpen ? 'auto' : '0px')};
  overflow: ${(p) => (p.isOpen ? 'initial' : 'hidden')};
  position: absolute;
  height: 100vh;
`

export const MobileMenu = styled(Collapse)`
  width: ${(p) => (p.isOpen ? 'auto' : '0px')};
  overflow: ${(p) => (p.isOpen ? 'initial' : 'hidden')};
  position: absolute;
  height: 100vh;
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`

export const DesktopMenu = styled.div`
  width: 300px;
  display: none;
  @media (min-width: 768px) {
    display: block;
    height: 100vh;
  }
`
