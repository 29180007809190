import { Form as FormikForm } from 'formik'
import styled from 'styled-components'
import { Input } from 'antd'

export const Form = styled(FormikForm)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: min-content;
`
export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
`

export const IpPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 100px;
`

export const CodeArea = styled.div`
  width: 500px;
  margin-top: 50px;
  display: ${(p) => (p.isShow ? 'none' : 'block')};
`

export const StyledInput = styled(Input)`
  width: 250px;
  margin-top: 10px;

  @media (min-width: 768px) {
    width: 400px;
  }
  @media (min-width: 1280px) {
    width: 500px;
  }
  &.searchInput {
    padding-right: 30px;
  }
`
export const InputsContainer = styled.div`
  display: flex;
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
`
export const Label = styled.div`
  width: 100%;
  font-size: 20px;
`
