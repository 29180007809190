import { connect } from 'react-redux';
import { WebsiteCheck as Self } from './WebsiteCheckPage'
import { setLoading } from '../../Layout/Layout.actions'
import { WebSiteCheckPage } from './WebsiteCheckPage.actions';


const mapStateToProps =({webSiteCheck,user}) => ({
  websiteCheckLoading:webSiteCheck.websiteCheckLoading,
  websiteCheckData:webSiteCheck.websiteCheckData,
  websiteCheckError:webSiteCheck.websiteCheckError,
  hostName: user.hostName,


})
const mapDispatchToProps = {
  setLoading,
  WebSiteCheckPage
}

export const WebsiteCheckPage = connect(mapStateToProps, mapDispatchToProps)(Self)
