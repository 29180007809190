import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Layout } from '../../Layout'
import * as S from './BackUp.styles'
import { Button, Select } from 'antd'
import { extOptions } from './BackUp.config'

const { Option } = Select

export const BackUpPage = ({
  hostName,
  hetznerCheck,
  backupData,
  backupLoading,
  restoreData,
  restoreLoading,
  restoreCheck,
}) => {
  const [backupHost, setBackupHost] = useState('43')
  const [username, setUsername] = useState('')

  return (
    <Layout>
      <S.BackUpPageContainer>
        <S.InputWrapper>
          <S.StyledInput
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <Select
            value={backupHost}
            style={{ width: 120 }}
            onChange={(e) => setBackupHost(e)}
          >
            {extOptions.map((item) => {
              return (
                <Option key={item.id} value={item.value}>
                  {item.label}
                </Option>
              )
            })}
          </Select>
        </S.InputWrapper>
        <S.ButtonContainer>
          <Button
            style={{ marginRight: '10px' }}
            loading={backupLoading}
            className="buttonAdd"
            onClick={() => hetznerCheck(username, backupHost, hostName)}
          >
            Check
          </Button>
          <Button
            loading={restoreLoading}
            className="buttonAdd"
            onClick={() => restoreCheck(username, backupHost, hostName)}
          >
            Restore
          </Button>
        </S.ButtonContainer>
        <code>
          {backupData && !backupLoading && (
            <div>
              {backupData.data.rawData.split('\n').map((i, index) => {
                return <div key={index}>{i}</div>
              })}
            </div>
          )}
        </code>

        <code>
          {restoreData && !restoreLoading && (
            <div>
              {restoreData.data.rawData.split('\n').map((i, index) => {
                return <div key={index}>{i}</div>
              })}
            </div>
          )}
        </code>
      </S.BackUpPageContainer>
    </Layout>
  )
}
