export const USER_TYPES = {
  LOGIN_REQUEST: 'USER_TYPES.LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_TYPES.LOGIN_SUCCESS',
  LOGIN_FAILED: 'USER_TYPES.LOGIN_FAILED',
  SET_USER_AUTH: 'USER_TYPES.SET_USER_AUTH',
  SET_USER_ROLE: 'USER_TYPES.SET_USER_ROLE',
  GET_ME_DATA_REQUEST: 'USER_TYPES.GET_ME_DATA_REQUEST',
  GET_ME_DATA_SUCCESS: 'USER_TYPES.GET_ME_DATA_SUCCESS',
  GET_ME_DATA_FAIL: 'USER_TYPES.GET_ME_DATA_FAIL',
  GET_HOSTNAME_REQUEST: 'USER_TYPES.GET_HOSTNAME_REQUEST',
  GET_HOSTNAME_SUCCESS: 'USER_TYPES.GET_HOSTNAME_SUCCESS',
  GET_HOSTNAME_FAIL: 'USER_TYPES.GET_HOSTNAME_FAIL',
  VERIFY_QR_CODE_REQUEST: 'USER_TYPES.VERIFY_QR_CODE_REQUEST',
  VERIFY_QR_CODE_SUCCESS: 'USER_TYPES.VERIFY_QR_CODE_SUCCESS',
  VERIFY_QR_CODE_FAIL: 'USER_TYPES.VERIFY_QR_CODE_FAIL',
  SET_DOMAIN_NAME: 'USER_TYPES.SEY_DOMAIN_NAME',
  SET_HOST_NAME: 'USER_TYPES.SET_HOST_NAME',
}

export const GET_USERS_TYPES = {
  GET_USERS_REQUEST: 'GET_USERS_TYPES.GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_TYPES.GET_USERS_SUCCESS',
  GET_USERS_FAIL: 'GET_USERS_TYPES.GET_USERS_FAIL',
}
