import { setDomainName } from '../../../common/user/user.actions'
import { FetchApi } from '../../../utils/FetchApi'
import { EDIT_QUOTA_TYPES } from './EditQuota.types'

export const EditQuotaAction =
  (mb, hostName, domainName) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_QUOTA_TYPES.EDIT_QUOTA_REQUEST,
      })

      const editQuota = await FetchApi.post('commands', {
        command: 'changeQuota',
        arg1: domainName,
        arg2: mb,
        host: hostName,
      })
      dispatch({
        type: EDIT_QUOTA_TYPES.EDIT_QUOTA_SUCCESS,
        payload: editQuota,
      })
    } catch (e) {
      dispatch({
        type: EDIT_QUOTA_TYPES.EDIT_QUOTA_FAILED,
        payload: e,
      })
    }
  }
export const CurrentQuotaAction = (value, hostName) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_QUOTA_TYPES.CURRENT_QUOTA_REQUEST,
    })
    const { data } = await FetchApi.post('commands', {
      command: 'currentQuota',
      arg1: value,
      host: hostName,
    })
    dispatch({
      type: EDIT_QUOTA_TYPES.CURRENT_QUOTA_SUCCESS,
      payload: data.data[0],
      host: hostName,
    })
  } catch (e) {
    dispatch({
      type: EDIT_QUOTA_TYPES.CURRENT_QUOTA_FAILED,
      payload: e,
    })
  }
}
