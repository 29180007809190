import { connect } from 'react-redux'
import { LoginPage as Self } from './LoginPage'
import { login } from '../../../common/user/user.actions'

const mapStateToProps = ({ user }) => ({
  loginLoading: user.loginLoading,
  signInErrors: user.logInError,
})

const mapDispatchToProps = {
  login,
}

export const LoginPage = connect(mapStateToProps, mapDispatchToProps)(Self)
