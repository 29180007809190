import React, { useEffect, useState } from 'react'
import { Layout } from '../../Layout'
import * as S from './AddNewUser.styles'
import { Formik } from 'formik'
import { Input, Button, Select } from 'antd'
import { initialValues, validationSchema } from './AddNewUser.config'
import { ROLES } from '../../../constants/roles'

const { Option } = Select

export const AddNewUserPage = ({
 
  AddNewUserAction,
  addNewUser,
  addNewUSerData,
  addNewUserLoading,
  addNewUserError,
  loginData,
  userRole,
}) => {
 
  return (
    <Layout>
      <S.AddNewUserContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            errors,
            touched,
          }) => (
            <S.Form>
              <S.SignInContainer>Add new user</S.SignInContainer>
              <S.StyledInput
                placeholder="Email"
                name="email"
                onChange={(e) => {
                  setFieldTouched(e.target.name)
                  handleChange(e)
                }}
                value={values.email}
                type="email"
              />
              {touched.email && !!errors.email && (
                <S.ErrorMessage>{errors.email}</S.ErrorMessage>
              )}
              <S.StyledInput
                value={values.password}
                onChange={(e) => {
                  setFieldTouched(e.target.name)
                  handleChange(e)
                }}
                name="password"
                placeholder="Password"
                type="password"
              />
              {touched.password && !!errors.password && (
                <S.ErrorMessage>{errors.password}</S.ErrorMessage>
              )}
              <S.Buttons>
                <Select
                  name="role"
                  defaultValue={0}
                  onChange={(val) => setFieldValue('role', val)}
                  style={{ width: '120px' }}
                >
                  <Option value={ROLES.USER}>User</Option>
                  <Option value={ROLES.MODERATOR}>Moderator</Option>
                  <Option value={ROLES.ADMIN}>Admin</Option>
                </Select>
                <Button
                  onClick={() => AddNewUserAction(values)}
                  disabled={errors.password || errors.email}
                  htmlType="submit"
                >
                  Add
                </Button>
              </S.Buttons>
            </S.Form>
          )}
        </Formik>
      </S.AddNewUserContainer>
    </Layout>
  )
}
