import { EMAIL_CHECK_PAGE_TYPES } from './EmailCheckPage.types'
import { FetchApi } from '../../../utils/FetchApi'

export const emailCheck = (values, hostName) => async (dispatch) => {
  try {
    dispatch({
      type: EMAIL_CHECK_PAGE_TYPES.EMAIL_CHECK_REQUEST,
    })
    const emailCheckData = await FetchApi.post('commands', {
      command: 'checkEmail',
      arg1: values.email,
      host: hostName,
    })
    dispatch({
      type: EMAIL_CHECK_PAGE_TYPES.EMAIL_CHECK_SUCCESS,
      payload: emailCheckData.data,
    })
  } catch (e) {
    dispatch({
      type: EMAIL_CHECK_PAGE_TYPES.EMAIL_CHECK_FAILED,
      payload: e,
    })
  }
}
