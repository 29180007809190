import { connect } from 'react-redux'
import { EditQuotaPage as Self } from './EditQuota'
import { setLoading } from '../../Layout/Layout.actions'
import {
  EditQuotaAction,
  CurrentQuotaAction,
} from '../EditQuota/EditQuota.actions'

const mapStateToProps = ({ editQuota, currentQuota, user }) => ({
  editQuotaError: editQuota.editQuotaError,
  editQuotaData: editQuota.editQuotaData,
  editQuotaLoading: editQuota.editQuotaLoading,
  hostName: user.hostName,
  domainName: user.domainName,
  currentQuotaData: currentQuota.currentQuotaData,
  currentQuotaLoading: currentQuota.currentQuotaLoading,
  currentQuotaError: currentQuota.currentQuotaError,
})

const mapDispatchToProps = {
  setLoading,
  EditQuotaAction,
  CurrentQuotaAction,
}

export const EditQuota = connect(mapStateToProps, mapDispatchToProps)(Self)
