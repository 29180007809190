import React from 'react'
import { Layout } from '../../Layout'
import { Input, Button } from 'antd'
import * as S from './OpenSite.styles'
import { Formik } from 'formik'
import { initialValues, validationSchema } from './OpenSite.config'

export const OpenSitePage = ({ setLoading, hostName, OpenSiteAction }) => {
  const handleUpdate = async (values) => {
    console.log('111')
    OpenSiteAction(values, `ext${hostName}.host.am`)
  }

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ values, errors, handleSubmit, handleChange, dirty }) => {
          return (
            <S.WebsiteCheckConatiner>
              <S.StyledInput
                name="domain"
                value={values.domain}
                onChange={handleChange}
              />

              {errors.domain && <div>{errors.domain}</div>}
              <Button disabled={errors.domain || !dirty} onClick={handleSubmit}>
                Open site
              </Button>
            </S.WebsiteCheckConatiner>
          )
        }}
      </Formik>
    </Layout>
  )
}
