import { CLOSE_SITE_TYPES } from './CloseSitePage.types'

const initialState = {
  closSitePageLoading: false,
  closSitePageError: null,
}

export const closeSitePage = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_SITE_TYPES.CLOSE_SITE_REQUEST:
      return {
        ...state,
        closSitePageLoading: true,
      }
    case CLOSE_SITE_TYPES.CLOSE_SITE_SUCCESS:
      return {
        ...state,
        closSitePageLoading: false,
      }
    case CLOSE_SITE_TYPES.CLOSE_SITE_FAILED:
      return {
        ...state,
        closSitePageLoading: false,
        closSitePageError: action.error,
      }
    default:
      return state
  }
}
