import { USER_TYPES, GET_USERS_TYPES } from './user.types'

const initialState = {
  logInError: null,
  isAuth: false,
  loginLoading: false,
  loginData: {},
  getMeLoading: false,
  getMeData: {},
  verifyLoading: false,
  verifyError: null,
  verifiedData: {},
  hostNameLoading: false,
  hostName: '40',
  domainName: '',
  getUsersData: [],
  getUsersLoading: false,
  getUsersError: null,
  token: null,
}

export const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPES.LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
        logInError: null,
      }
    case USER_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        loginLoading: false,
        logInError: null,
        loginData: action.payload,
      }
    case USER_TYPES.LOGIN_FAILED:
      return {
        ...state,
        loginLoading: false,
        logInError: action.error,
      }
    case USER_TYPES.SET_USER_AUTH:
      return {
        ...state,
        isAuth: action.isAuth,
        token: localStorage.getItem('token'),
      }
    case USER_TYPES.GET_ME_DATA_REQUEST:
      return {
        ...state,
        getMeLoading: true,
      }
    case USER_TYPES.GET_ME_DATA_SUCCESS:
      return {
        ...state,
        getMeLoading: false,
        getMeData: action.payload,
      }
    case USER_TYPES.GET_ME_DATA_FAIL:
      return {
        ...state,
        getMeLoading: false,
      }
    case USER_TYPES.VERIFY_QR_CODE_REQUEST:
      return {
        ...state,
        verifyLoading: true,
        verifiedData: action.payload,
      }
    case USER_TYPES.VERIFY_QR_CODE_SUCCESS:
      return {
        ...state,
        verifyLoading: false,
        verifiedData: action.payload,
        token: action.payload.accessToken,
      }
    case USER_TYPES.VERIFY_QR_CODE_FAIL:
      return {
        ...state,
        verifyLoading: false,
        error: action.error,
      }
    case USER_TYPES.GET_HOSTNAME_REQUEST:
      return {
        ...state,
        hostNameLoading: true,
      }
    case USER_TYPES.GET_HOSTNAME_SUCCESS:
      return {
        ...state,
        hostName: action.payload,
        hostNameLoading: false,
      }
    case USER_TYPES.GET_HOSTNAME_FAIL:
      return {
        ...state,
        hostNameLoading: false,
      }
    case USER_TYPES.SET_DOMAIN_NAME:
      return {
        ...state,
        domainName: action.domainName,
      }
    case USER_TYPES.SET_HOST_NAME:
      return {
        ...state,
        hostName: action.hostName,
      }
    case GET_USERS_TYPES.GET_USERS_REQUEST:
      return {
        ...state,
        getUsersLoading: true,
        getUsersError: null,
      }
    case GET_USERS_TYPES.GET_USERS_SUCCESS:
      return {
        ...state,
        getUsersLoading: false,
        getUsersData: action.payload,
        getUsersError: null,
      }
    case GET_USERS_TYPES.GET_USERS_FAIL:
      return {
        ...state,
        getUsersLoading: false,
        getUsersError: action.error,
        getUsersData: [],
      }
    default:
      return state
  }
}
