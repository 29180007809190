import { connect } from 'react-redux'
import { QRCodePage as Self } from './QRCodePage'
import { verifyQR } from '../../../common/user/user.actions'

const mapStateToProps = ({ user }) => ({
  qrCode: user.loginData.qr_code,
  secretHash: user.loginData.secretHash,
})

const mapDispatchToProps = {
  verifyQR,
}

export const QRCodePage = connect(mapStateToProps, mapDispatchToProps)(Self)
