import { connect } from 'react-redux'
import { EmailCheckPage as Self } from './EmailCheckPage'
import { emailCheck } from './EmailCheckPage.actions'

const mapStateToProps = ({ emailCheckPage, user }) => ({
  emailCheckData: emailCheckPage.emailCheckData,
  emailCheckError: emailCheckPage.emailCheckError,
  emailCheckLoading: emailCheckPage.emailCheckLoading,
  loginData: user.loginData,
  hostName: user.hostName,
})

const mapDispatchToProps = {
  emailCheck,
}

export const EmailCheckPage = connect(mapStateToProps, mapDispatchToProps)(Self)
