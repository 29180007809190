import { connect } from 'react-redux'
import { VerifyPage as Self } from './VerifyPage'
import { verifyQR } from '../../../common/user/user.actions'

const mapStateToProps = ({ user }) => ({
  secretHash: user.loginData.secretHash,
})

const mapDispatchToProps = {
  verifyQR,
}

export const VerifyPage = connect(mapStateToProps, mapDispatchToProps)(Self)
