import { ADD_NEW_USER_TYPES } from './AddNewUser.types'
const initialState = {
  addNewUser: null,
  addNewUserData: null,
  addNewUserLoading: false,
  addNewUserError: null,
}
export const addNewUser = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_USER_TYPES.ADD_NEW_USER_REQUEST:
      return {
        ...state,
        addNewUserLoading: true,
      }
    case ADD_NEW_USER_TYPES.ADD_NEW_USER_SUCCES:
      return {
        ...state,
        addNewUserData: action.payload,
      }
    case ADD_NEW_USER_TYPES.ADD_NEW_USER_FAILED:
      return {
        ...state,
        addNewUserError: action.error,
      }
    default:
      return state
  }
}
