import { combineReducers } from 'redux'
import { closeSitePage } from '../components/pages/CloseSitePage/CloseSitePage.reducer'
import { user } from '../common/user/user.reducer'
import { layout } from '../components/Layout/Layout.reducer'
import { ipPage } from '../components/pages/IpPage/IpPage.reducer'
import { addNewUser } from '../components/pages/AddNewUser/AddNewUser.reducer'
import { emailCheckPage } from '../components/pages/EmailCheckPage/EmailCheckPage.reducer'
import { ipCheckByEmail } from '../components/pages/IpCheckByEmail/IpCheckByEmail.reducer'
import { openSite } from '../components/pages/OpenSite/OpenSite.reducer'
import { editQuota } from '../components/pages/EditQuota/EditQuota.reducer'
import { webSiteCheck } from '../components/pages/WebsiteCheckPage/WebsiteCheckPage.reducer'
import { currentQuota } from '../components/pages/EditQuota/EditQuota.reducer'
import { editBandwidth } from '../components/pages/Bandwidth/Bandwidth.reducer'
import { currentBandwidth } from '../components/pages/Bandwidth/Bandwidth.reducer'
import { hetznerCheckReducer } from '../components/pages/BackUp/BackUp.reducer'
import { checkRestore } from '../components/pages/BackUp/BackUp.reducer'
import { deleteUserReducer } from '../components/pages/DeleteUserPage/DeleteUserPage.reducer'

export const rootReducer = combineReducers({
  addNewUser,
  closeSitePage,
  user,
  layout,
  ipPage,
  emailCheckPage,
  ipCheckByEmail,
  openSite,
  editQuota,
  webSiteCheck,
  currentQuota,
  editBandwidth,
  currentBandwidth,
  hetznerCheckReducer,
  checkRestore,
  deleteUserReducer,
})
