import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { items } from './LeftSideBar.helpers'
import { Menu } from 'antd'
import * as S from './LeftSideBar.styles'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { FetchApi } from '../../../../utils/FetchApi'

export const LeftSideBar = ({ logout, getMeData }) => {
  const location = useLocation()
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const [sideBarItems, setSideBarItems] = useState([])

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    setSideBarItems(
      items.filter((el) =>
        el.roles ? el.roles.includes(getMeData.role) : false
      )
    )
  }, [getMeData])

  return (
    <>
      <S.MobileMenu isOpen={isOpen}>
        <S.StyledSider>
          <Menu
            theme="dark"
            selectedKeys={[location.pathname]}
            mode="inline"
            style={{ marginTop: '20px', backgroundColor: '#af69ed' }}
          >
            {items
              .filter((el) =>
                !!el.roles ? el.roles.includes(getMeData.role) : true
              )
              .map((item) => (
                <Menu.Item
                  style={{ color: '#000' }}
                  key={item.path}
                  onClick={() => {
                    handleToggle()
                    history.push(item.path)
                  }}
                >
                  {item.name}
                </Menu.Item>
              ))}

            <Menu.Item
              key={'logout'}
              onClick={() => {
                logout(() => history.push('login'))
                FetchApi.removeToken()
              }}
              style={{ color: 'black' }}
            >
              Logout
            </Menu.Item>
          </Menu>
        </S.StyledSider>
      </S.MobileMenu>
      <S.DesktopMenu>
        <S.StyledSider>
          <Menu
            theme="dark"
            selectedKeys={[location.pathname]}
            mode="inline"
            style={{
              marginTop: '20px',
              fontSize: '17px',
              backgroundColor: '#af69ed',
            }}
          >
            {items
              .filter((el) =>
                !!el.roles ? el.roles.includes(getMeData.role) : true
              )
              .map((item) => (
                <Menu.Item
                  style={{ color: '#fff' }}
                  key={item.path}
                  onClick={() => {
                    handleToggle()
                    history.push(item.path)
                  }}
                >
                  {item.name}
                </Menu.Item>
              ))}
            <Menu.Item
              key={'desktopLogout'}
              onClick={() => {
                logout(() => history.push('login'))
                FetchApi.removeToken()
              }}
              style={{ color: '#ccc', fontWeight: '500' }}
            >
              Logout
            </Menu.Item>
          </Menu>
        </S.StyledSider>
      </S.DesktopMenu>

      <S.ToggleBtn
        isOpen
        onClick={() => {
          handleToggle()
        }}
      >
        {isOpen ? (
          <AiOutlineClose
            style={{ color: '#ccc', left: '10px', position: 'absolute' }}
            size={25}
          />
        ) : (
          <AiOutlineMenu
            style={{ color: '#ccc', left: '10px', position: 'absolute' }}
            size={25}
          />
        )}
      </S.ToggleBtn>
    </>
  )
}
