import { connect } from 'react-redux'
import {
  getHostName,
  setDomainName,
  setHostName,
  getUserData,
} from '../../common/user/user.actions'
import { Layout as Self } from './Layout'

const mapStateToProps = (state) => ({
  loading: state.layout.loading,
  hostName: state.user.hostName,
  token: state.user.token,
})

const mapDispatchToProps = {
  getHostName,
  setDomainName,
  setHostName,
  getUserData,
}
export const Layout = connect(mapStateToProps, mapDispatchToProps)(Self)
