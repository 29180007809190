import { OPEN_SITE } from './OpenSite.types'
const initialState = {
  openSiteLoading: false,
  openSiteError: null,
  openSiteData: null,
}
export const openSite = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SITE.OPEN_SITE_REQUEST:
      return {
        ...state,
        openSiteLoading: true,
      }
    case OPEN_SITE.OPEN_SITE_SUCCESS:
      return {
        ...state,
        openSiteLoading: false,
        openSiteData: action.payload,
      
      }
    case OPEN_SITE.OPEN_SITE_FAILED:
      return {
        ...state,
        openSiteLoading: false,
        openSiteError:action.error
      }
    default:
      return state
  }
}
