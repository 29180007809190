import { FetchApi } from '../../utils/FetchApi'
import { USER_TYPES, GET_USERS_TYPES } from './user.types'

export const logout = (cb) => (dispatch) => {
  FetchApi.removeToken()

  dispatch({
    type: USER_TYPES.SET_USER_AUTH,
    isAuth: false,
  })

  if (typeof cb === 'function') cb()
}

export const setUserAuth = (token) => (dispatch) => {
  const token = FetchApi.getToken()

  if (token) {
    dispatch({
      type: USER_TYPES.SET_USER_AUTH,
      isAuth: true,
    })
  }
}

export const login =
  (values, options = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_TYPES.LOGIN_REQUEST,
      })

      const { data } = await FetchApi.post('user/login', values, {
        noToken: true,
      })

      dispatch({
        type: USER_TYPES.LOGIN_SUCCESS,
        payload: data,
      })

      if (data.shouldBeVerified && typeof options.onTotp === 'function')
        return options.onTotp()
      if (!data.shouldBeVerified) return options.onVerified()
      if (typeof options.onSuccess === 'function') {
        options.onSuccess()
      }
    } catch (e) {
      dispatch({
        type: USER_TYPES.LOGIN_FAILED,
        error: e.data.errors ? e.data.errors : e,
      })
      // alert('Something went wrong')
    }
  }

export const verifyQR = (options, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: USER_TYPES.VERIFY_QR_CODE_REQUEST })
    const { data } = await FetchApi.post('user/verify-qrcode', options, {
      noToken: true,
    })

    dispatch({
      type: USER_TYPES.VERIFY_QR_CODE_SUCCESS,
      payload: data,
    })

    FetchApi.setToken(data.accessToken)
    if (typeof onSuccess === 'function') onSuccess()
  } catch (e) {
    dispatch({
      type: USER_TYPES.VERIFY_QR_CODE_FAIL,
      error: alert('Wrong verify code'),
    })
  }
}

export const getUserData = () => async (dispatch) => {
  try {
    dispatch({ type: USER_TYPES.GET_ME_DATA_REQUEST })

    const { data } = await FetchApi.get('user/me')

    dispatch({
      type: USER_TYPES.GET_ME_DATA_SUCCESS,
      payload: data,
    })
  } catch (e) {
    dispatch({ type: USER_TYPES.GET_ME_DATA_FAIL })
  }
}

export const getHostName = (value) => async (dispatch) => {
  try {
    dispatch({
      type: USER_TYPES.GET_HOSTNAME_REQUEST,
    })
    const { data } = await FetchApi.get(`commands/get-host-name/${value}`)
    if (!data.length) {
      throw new Error('Invalid domain')
    }
    let hostName = data[0].hostname
    let lastTwoNumbers = hostName.match(/\d{2}(?=\D*$)/)[0]

    console.log(lastTwoNumbers)
    dispatch({
      type: USER_TYPES.GET_HOSTNAME_SUCCESS,
      payload: lastTwoNumbers,
    })
  } catch (e) {
    console.log(e)
    dispatch({
      type: USER_TYPES.GET_HOSTNAME_FAIL,
    })
    alert(e)
  }
}

export const setDomainName = (domainName) => (dispatch) => {
  dispatch({
    type: USER_TYPES.SET_DOMAIN_NAME,
    domainName,
  })
}

// HOST NAME IS JUST THE NUMBER (extXX.host.am)
export const setHostName = (hostName) => (dispatch) => {
  dispatch({
    type: USER_TYPES.SET_HOST_NAME,
    hostName,
  })
}

export const getUsers = (onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USERS_TYPES.GET_USERS_REQUEST,
    })
    const userData = await FetchApi.get('user')

    dispatch({
      type: GET_USERS_TYPES.GET_USERS_SUCCESS,
      payload: userData.data.users,
    })
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    console.log(e)
    dispatch({
      type: GET_USERS_TYPES.GET_USERS_REQUEST,
      error: e.data.errors ? e.data.errors : e,
    })
  }
}
