import React, { useState, useCallback } from 'react';
import * as S from './QRCodePage.styles'
import ReactCodeInput from 'react-verification-code-input';
import { Formik, Form } from 'formik'
import { initialValues } from '../LoginPage/LoginPage.config';

import { Button } from 'antd'

export const QRCodePage = ({ qrCode, verifyQR, secretHash,QrCode, history }) => {

  const [qrInputValue, setQrInputValue] = useState('')

  const handleSubmit = () => {
    verifyQR({ token: qrInputValue, secretHash }, () => history.push('/main'))
  }

  return (
    <S.VerifyContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          submitForm
        }) => (
          <Form>
            <S.QRCode>
              <S.QRCodeImage src={qrCode} />
            </S.QRCode>
            <ReactCodeInput onChange={(value) => setQrInputValue(value)} />
            <S.VerifyButton
              type='submit'
            >
              Verify
          </S.VerifyButton>
          </Form>
        )
        }
      </Formik>
    </S.VerifyContainer>

  )
}
