import React, { useState } from 'react'
import { Layout } from '../../Layout'
import { Button } from 'antd'
import * as S from './WebsiteCheckPage.styles'
import { Formik } from 'formik'
import { initialValues, validationSchema } from './WebsiteCheckPage.config'

export const WebsiteCheck = ({
  WebSiteCheckPage,
  websiteCheckLoading,
  websiteCheckData,
  websiteCheckError,
  hostName,
}) => {
  return (
    <Layout>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ values, errors, handleSubmit, handleChange, dirty }) => {
          return (
            <S.WebsiteCheckConatiner>
              <S.InputWrapper>
                <S.Label>Domain</S.Label>
                <S.StyledInput
                  placeholder="internet.am"
                  name="domain"
                  value={values.domain}
                  onChange={handleChange}
                />
              </S.InputWrapper>
              {errors.domain && <div>{errors.domain}</div>}
              <Button
                disabled={errors.domain || !dirty}
                loading={websiteCheckLoading}
                onClick={() => {
                  WebSiteCheckPage(values, `ext${hostName}.host.am`)
                }}
              >
                Check
              </Button>

              {websiteCheckData && !websiteCheckLoading && (
                <div>
                  {websiteCheckData.data.rawData.split('\n').map((i, index) => {
                    return <div key={index}>{i}</div>
                  })}
                </div>
              )}
            </S.WebsiteCheckConatiner>
          )
        }}
      </Formik>
    </Layout>
  )
}
