import React, { useState, useCallback } from 'react'
import { Layout } from '../../Layout'
import { Input, Button } from 'antd'
import * as S from './EditQuota.styles'
import { Formik } from 'formik'
import { initialValues, validationSchema } from './EditQuota.config'
import { useEffect } from 'react'

export const EditQuotaPage = ({
  EditQuotaAction,
  CurrentQuotaAction,
  updateQuotaData,
  editQuotaLoading,
  editQuotaData,
  editQuotaError,
  currentQuotaLoading,
  hostName,
  domainName,
  currentQuotaData,
}) => {
  const [mb, setMb] = useState(currentQuotaData)

  const updateQuota = () => {
    EditQuotaAction(
      mb,
      hostName.startsWith('ext') ? hostName : `ext${hostName}.host.am`,
      domainName
    )
  }

  const checkQuota = () => {
    CurrentQuotaAction(
      domainName,
      hostName.startsWith('ext') ? hostName : `ext${hostName}.host.am`
    )
  }

  useEffect(() => {
    setMb(currentQuotaData)
  }, [currentQuotaData])

  const editQuota = (e) => {
    setMb(e.target.value)
  }

  return (
    <Layout>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          dirty,
          handleBlur,
        }) => {
          return (
            <S.WebsiteCheckConatiner>
              <S.InputWrapper>
                <S.StyledInput
                  className="Checked"
                  placeholder=" Domain Name"
                  name="domain"
                  value={domainName}
                  disabled
                  onChange={handleChange}
                />

                <S.StyledInput
                  type="text"
                  placeholder="check mb"
                  name="checkMb"
                  value={currentQuotaData}
                  disabled
                />

                <S.StyledInput
                  type="number"
                  placeholder="example. 300"
                  name="megabytes"
                  value={mb}
                  onChange={(e) => editQuota(e)}
                />
              </S.InputWrapper>

              <S.ButtonsContainer>
                <Button
                  className="button"
                  loading={currentQuotaLoading}
                  onClick={checkQuota}
                >
                  Check
                </Button>

                <Button
                  className="buttonAdd"
                  loading={editQuotaLoading}
                  onClick={updateQuota}
                >
                  Add Mb
                </Button>
              </S.ButtonsContainer>
            </S.WebsiteCheckConatiner>
          )
        }}
      </Formik>
    </Layout>
  )
}
