import { WEBSITE_CHECK_PAGE_TYPES } from './WebsiteCheckPage.types'
const initialState = {
  websiteCheckLoading: false,
  websiteCheckData: null,
  websiteCheckError: null,
}
export const webSiteCheck = (state = initialState, action) => {
  switch (action.type) {
    case WEBSITE_CHECK_PAGE_TYPES.WEBSITE_CHECK_PAGE_REQUEST:
      return {
        ...state,
        websiteCheckLoading: true,
      }
    case WEBSITE_CHECK_PAGE_TYPES.WEBSITE_CHECK_PAGE_SUCCESS:
      return {
        ...state,
        websiteCheckLoading: false,
        websiteCheckData: action.payload,
      }
    case WEBSITE_CHECK_PAGE_TYPES.WEBSITE_CHECK_PAGE_FAILED:
      return {
        ...state,
        websiteCheckError: action.error,
        websiteCheckLoading: false,
      }
    default:
      return state
  }
}
