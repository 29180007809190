import { connect } from 'react-redux'
import { BandwidthPage as Self } from './Bandwidth'
import { setLoading } from '../../Layout/Layout.actions'
import { BandwidthAction, CurrentBandwidthAction } from './Bandwidth.actions'

const mapStateToProps = ({ editBandwidth, currentBandwidth, user }) => ({
  editBandwidthError: editBandwidth.editBandwidthError,
  editBandwidthData: editBandwidth.editBandwidthData,
  editBandwidthLoading: editBandwidth.editBandwidthLoading,
  hostName: user.hostName,
  domainName: user.domainName,
  currentBandwidthData: currentBandwidth.currentBandwidthData,
  currentBandwidthLoading: currentBandwidth.currentBandwidthLoading,
  currentBadwidthError: currentBandwidth.currentBandwidthError,
  currentBandwidthData: currentBandwidth.currentBandwidthData,
})

const mapDispatchToProps = {
  setLoading,
  BandwidthAction,
  CurrentBandwidthAction,
}

export const EditBandwidth = connect(mapStateToProps, mapDispatchToProps)(Self)
