import { IP_CHECK_BY_EMAIL } from './IpCheckByEmail.types'
const initialState = {
  ipCheckByEmail: null,
  ipCheckByEmailLoading: false,
  ipCheckByEmailData: null,
}
export const ipCheckByEmail = (state = initialState, action) => {
  switch (action.type) {
    case IP_CHECK_BY_EMAIL.IP_CHECK_BY_EMAIL_REQUEST:
      return {
        ...state,
        ipCheckByEmail: null,
        ipCheckByEmailLoading: true,
      }
    case IP_CHECK_BY_EMAIL.IP_CHECK_BY_EMAIL_SUCCESS:
      return {
        ...state,
        ipCheckByEmailLoading: false,
        ipCheckByEmailData: action.payload,
      }
    case IP_CHECK_BY_EMAIL.IP_CHECK_BY_EMAIL_FAILED:
      return {
        ...state,
        ipCheckByEmailLoading: false,
      }
    default:
      return state
  }
}
