import { USER_NAME_TYPES } from './BackUp.types'

const initialState = {
  backupData: null,
  backupLoading: false,
  backupError: null,
  restoreData: null,
  restoreLoading: false,
  restoreError: null,
}

export const hetznerCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_NAME_TYPES.USER_NAME_REQUEST:
      return {
        ...state,
        backupLoading: true,
      }
    case USER_NAME_TYPES.USER_NAME_SUCCESS:
      return {
        ...state,
        backupLoading: false,
        backupData: action.payload,
      }
    case USER_NAME_TYPES.USER_NAME_FAIL:
      return {
        ...state,
        backupLoading: false,
        backupError: action.error,
      }
    default:
      return state
  }
}
export const checkRestore = (state = initialState, action) => {
  switch (action.type) {
    case USER_NAME_TYPES.RESTORE_REQUEST:
      return {
        restoreLoading: true,
      }
    case USER_NAME_TYPES.RESTORE_SUCCESS:
      return {
        ...state,
        restoreLoading: false,
        restoreData: action.payload,
      }
    case USER_NAME_TYPES.RESTORE_FAIL:
      return {
        ...state,
        restoreLoading: false,
        restoreError: action.error,
      }
    default:
      return state
  }
}
