import { FetchApi } from '../../../utils/FetchApi'
import { WEBSITE_CHECK_PAGE_TYPES } from './WebsiteCheckPage.types'

export const WebSiteCheckPage = (values, hostName) => async (dispatch) => {
  try {
    dispatch({
      type: WEBSITE_CHECK_PAGE_TYPES.WEBSITE_CHECK_PAGE_REQUEST,
    })
    const checkWebsite = await FetchApi.post('commands', {
      command: 'checkHosting',
      arg1: values.domain,
      host: hostName,
    })
  
    dispatch({
      type: WEBSITE_CHECK_PAGE_TYPES.WEBSITE_CHECK_PAGE_SUCCESS,
      payload: checkWebsite,
    })
  } catch (e) {
    dispatch({
      type: WEBSITE_CHECK_PAGE_TYPES.WEBSITE_CHECK_PAGE_FAILED,
      payload: e.message,
    })
  }
}
