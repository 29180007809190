import { connect } from 'react-redux'
import { IpPage as Self } from './IpPage'
import { setLoading } from '../../Layout/Layout.actions'
import { checkIpAddress, unblockIpAddress,rateLimitAction } from './IpPage.actions'

const mapStateToProps = ({ ipPage, user }) => ({
  rateLimitData: ipPage.rateLimitData,
  rateLimitLoading: ipPage.rateLimitLoading,
  rateLimitError: ipPage.rateLimitError,
  ipCheckData: ipPage.ipCheckData,
  ipCheckError: ipPage.ipCheckError,
  ipCheckLoading: ipPage.ipCheckLoading,
  ipUnblockData: ipPage.ipUnblockData,
  ipUnblockError: ipPage.ipUnblockError,
  ipUnblockLoading: ipPage.ipUnblockLoading,
  hostName: user.hostName,
})

const mapDispatchToProps = {
  setLoading,
  checkIpAddress,
  unblockIpAddress,
  rateLimitAction
}

export const IpPage = connect(mapStateToProps, mapDispatchToProps)(Self)
