import * as yup from 'yup'

export const initialValues = {
  domain: '',
}

const patterns = {
  domain:
    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
  punycode:
    /^([A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?)*)(\.?)$/,
  cyrillicDomain:
    /^((http|https):\/\/)?[a-zа-я0-9]+([\-\.]{1}[a-zа-я0-9]+)*\.[a-zа-я]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
}

yup.addMethod(
  yup.string,
  'domain',
  function pattern(name, message = 'Wrong domain format') {
    const domainRules = [
      patterns.domain,
      patterns.punycode,
      patterns.cyrillicDomain,
    ]

    return this.test({
      message,
      test: (value) =>
        value === null ||
        value === '' ||
        value === undefined ||
        domainRules.some((regex) => regex.test(value)),
    })
  }
)

export const validationSchema = yup
  .object()
  .noUnknown()
  .shape({
    domain: yup
      .string()
      .domain()
      .required()
      .required('Please input your domain')
      .test('domainCheck', 'Wrong domain format', (domain) => {
        return domain?.match(
          /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/
        )
      }),
  })
