import styled from 'styled-components/macro'

export const QRCode = styled.div`
  font-size: 200px;
  display: flex;
`
export const QRCodeImage = styled.img`
  width: 300px;
  height: 300px;
`
export const VerifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
export const VerifyButton = styled.button`
  margin-top: 20px;
  cursor: pointer;
  padding: 5px 20px;
  color: #001529;
  border: none;
`