import React from 'react'
import { Layout } from '../../Layout'
import { Button } from 'antd'
import * as S from './CloseSitePage.styles'
import { Formik } from 'formik'
import { initialValues, validationSchema } from './CloseSitePage.config'
import { CloseSite } from './CloseSitePage.actions'

export const CloseSitePage = ({
  CloseSite,
  setLoading,
  hostName,
  closSitePageError,
}) => {
  const handleSubmit = async (values) => {
    setLoading(true)
    await CloseSite(values, `ext${hostName}.host.am`)
    setLoading(false)
  }

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleSubmit, handleChange, dirty }) => {
          return (
            <S.WebsiteCheckConatiner>
              <S.StyledInput
                name="domain"
                value={values.domain}
                onChange={handleChange}
                placeholder="Domain to close"
              />
              {errors.domain && <div>{errors.domain}</div>}
              <S.StyledInput
                name="ip"
                value={values.ip}
                onChange={handleChange}
                placeholder="IP address to unblock access"
              />
              {errors.ip && <div>{errors.ip}</div>}
              <Button
                htmlType="submit"
                disabled={errors.domain || !dirty}
                onClick={handleSubmit}
              >
                Close site
              </Button>
            </S.WebsiteCheckConatiner>
          )
        }}
      </Formik>
    </Layout>
  )
}
