import { FetchApi } from '../../../utils/FetchApi'
import { ADD_NEW_USER_TYPES } from './AddNewUser.types'

export const AddNewUserAction = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_NEW_USER_TYPES.ADD_NEW_USER_REQUEST,
    })
    const userData = await FetchApi.post('user', values)
    dispatch({
      type: ADD_NEW_USER_TYPES.ADD_NEW_USER_SUCCES,
      payload: userData,
    })
  } catch (e) {
    dispatch({
      type: ADD_NEW_USER_TYPES.ADD_NEW_USER_FAILED,
      payload: e,
    })
  }
}
