import React, { useState } from 'react'
import { Layout } from '../../Layout'
import { Input, Button } from 'antd'
import * as S from './EmailCheckPage.styles'
import { Formik } from 'formik'
import { initialValues, validationSchema } from './EmailCheckPage.config'

export const EmailCheckPage = ({
  emailCheck,
  emailCheckData,
  emailCheckError,
  emailCheckLoading,
  loginData,
  hostName,
}) => {
  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={validationSchema}
      >
        {({
          errors,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          dirty,
        }) => (
          <div>
            <S.EmailCheckPageConatiner>
              <S.InputWrapper>
                <S.Label className="Check">Email check</S.Label>
                {
                  <S.StyledInput
                    placeholder="pogos.pogosyan@gmail.com"
                    name="email"
                    type="email"
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                }
              </S.InputWrapper>
              {errors.email && <p className="error">{errors.email}</p>}

              <Button
                disabled={errors.email || !dirty}
                loading={emailCheckLoading}
                onClick={() => {
                  emailCheck(values, `ext${hostName}.host.am`)
                }}
                htmlType="submit"
              >
                Check
              </Button>

              {emailCheckData && !emailCheckLoading && (
                <div style={{ fontWeight: 'bold' }}>
                  <div>
                    {emailCheckData.rawData.split('\n').map((i, index) => {
                      return <div key={index}>{i}</div>
                    })}
                  </div>
                </div>
              )}
            </S.EmailCheckPageConatiner>
          </div>
        )}
      </Formik>
    </Layout>
  )
}
