import { DELETE_USER_TYPES, USER_UPDATE_TYPES } from './DeleteUserPage.types'
import { FetchApi } from '../../../utils/FetchApi'

export const deleteUser = (values, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_TYPES.DELETE_USER_REQUEST,
    })
    const userData = await FetchApi.delete(`user/${values.email}`)

    dispatch({
      type: DELETE_USER_TYPES.DELETE_USER_SUCCESS,
      payload: userData,
    })
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    console.log(e)
    dispatch({
      type: DELETE_USER_TYPES.DELETE_USER_FAIL,
      error: e.data.errors ? e.data.errors : e,
    })
  }
}

export const updateUser = (values, email, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_TYPES.UPDATE_USER_REQUEST,
    })
    const userData = await FetchApi.put(`user/${email}`, values)

    dispatch({
      type: USER_UPDATE_TYPES.UPDATE_USER_SUCCESS,
      payload: userData,
    })
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    dispatch({
      type: USER_UPDATE_TYPES.UPDATE_USER_FAIL,
      error: e.data.errors ? e.data.errors : e,
    })
  }
}
