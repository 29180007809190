import { connect } from 'react-redux'
import { OpenSitePage as Self } from './OpenSite'
import { setLoading } from '../../Layout/Layout.actions'
import { OpenSiteAction } from './OpenSite.actions'

const mapStateToProps = ({ user }) => ({
  hostName: user.hostName,
})
const mapDispatchToProps = {
  setLoading,
  OpenSiteAction,
}

export const OpenSite = connect(mapStateToProps, mapDispatchToProps)(Self)
