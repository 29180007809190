import styled from 'styled-components/macro'
import { BiSearchAlt2 } from 'react-icons/bi'
import { Content } from 'antd/lib/layout/layout'
import { Input } from 'antd'
import { Layout as AntLayout } from 'antd'

export const LayoutContainer = styled(AntLayout)`
  flex-direction: column;
  height: 100%  ;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`
export const SearchIcon = styled(BiSearchAlt2)`
  fill: #fff;
  margin-right: 10px;
  cursor: pointer;
`

export const Layout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

export const StyledContent = styled(Content)`
  height: 100vh;
  width: 100%;
  padding-top: 70px;
`

export const Loader = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  // background: #001529;
  background: #af69ed;
  padding: 10px 50px;

  @media (min-width: 768px) {
    position: absolute;
    padding: 15px 30px;
  }
`
export const StyledInput = styled(Input)`
  width: 100px;
  margin-right: 20px;
  &.host {
    text-align: center;
    margin-left: 10px;
    width: 100px;
  }
  &.search {
    padding-right: 30px;
  }
  @media (min-width: 768px) {
    width: 220px;

    &.host {
      text-align: center;
      margin-left: 10px;
      width: 150px;
    }
    &.search {
      padding-right: 30px;
    }
  }
`
export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`
export const Dropbtn = styled.div`
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  :hover {
    background-color: #2980b9;
  }
`
export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`
