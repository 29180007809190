import * as yup from 'yup'

export const initialValues = {
  email: '',
}

export const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Wrong email format')
    .required('Email is required')
})