import { LAYOUT_TYPES } from './Layout.types'

const initialState = {
  loading: false,
}

export const layout = (state = initialState, action) => {
  switch (action.type) {
    case LAYOUT_TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
