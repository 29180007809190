import React, { useEffect, useState } from 'react'
import * as S from './VerifyPage.styles'
import ReactCodeInput from 'react-verification-code-input'
import { Formik, Form } from 'formik'
import { initialValues } from '../LoginPage/LoginPage.config'

export const VerifyPage = ({ verifyQR, secretHash, history }) => {
  const [verifyValue, setVerifyValue] = useState('')

  const handleEnter = () => {
    verifyQR({ token: verifyValue, secretHash }, () => history.push('/main'))
  }

  // useEffect(() => {
  //   if (verifyValue.length === 6) {
  //     handleEnter()
  //   }
  // }, [verifyValue])

  return (
    <S.VerifyContainer>
      <Formik initialValues={initialValues} onSubmit={handleEnter}>
        {({ handleSubmit, submitForm }) => (
          <Form>
            <S.VerifyHeader>Verification</S.VerifyHeader>
            <ReactCodeInput onChange={(value) => setVerifyValue(value)} />
            <S.VerifyButton type="submit">Verify</S.VerifyButton>
          </Form>
        )}
      </Formik>
    </S.VerifyContainer>
  )
}
