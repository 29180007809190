import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import './App.css'
import 'antd/dist/antd.css'
import { Routes } from './components/Routes'
import {
  setUserAuth as setUserAuthAction,
  getUserData as getUserDataAction,
} from './common/user/user.actions'

const mapDispatchToProps = {
  setUserAuth: setUserAuthAction,
  getUserData: getUserDataAction,
}

function App({ setUserAuth, getUserData }) {
  const [isInit, setIsInit] = useState(false)
  useEffect(() => {
    setUserAuth()
    setTimeout(() => {
      setIsInit(true)
    }, 100)
  }, [setUserAuth])

  if (!isInit) return null

  return <Routes />
}

export default connect(undefined, mapDispatchToProps)(App)
