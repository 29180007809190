import * as yup from 'yup'

export const initialValues = {
  ipAddress: '',
}

export const validationsSchema = yup.object().shape({
  ipAddress: yup
    .string()
    .required('Please input your IP address')
    .test('ipCheck', 'Wrong ip format', (ipAddress) => {
      return ipAddress?.match(/(^(\d{1,3}\.){3}(\d{1,3})$)/)
    })
    .test('ipCheck', 'Wrong ip format', (ipAddress) => {
      return ipAddress === undefined || ipAddress.trim() === ''
        ? true
        : ipAddress.split('.').find((i) => parseInt(i, 10) > 255) === undefined
    }),
})
