import React from 'react';
import { Layout } from '../../Layout';
import * as S from './WelcomePage.styles';


export const WelcomePage = () => {
    return (
      <Layout>

      </Layout>
    )
}