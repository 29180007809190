import { CLOSE_SITE_TYPES } from '../CloseSitePage/CloseSitePage.types'
import { FetchApi } from '../../../utils/FetchApi'

export const CloseSite = (values, hostName) => async (dispatch) => {
  try {
    dispatch({
      type: CLOSE_SITE_TYPES.CLOSE_SITE_REQUEST,
    })
    const data = await FetchApi.post('commands', {
      command: 'closeSitePage',
      arg1: values.domain,
      arg2: values.ip,
      host: hostName,
    })
    dispatch({
      type: CLOSE_SITE_TYPES.CLOSE_SITE_SUCCESS,
      payload: data,
    })
  } catch (e) {
    dispatch({
      type: CLOSE_SITE_TYPES.CLOSE_SITE_FAILED,
      payload: e,
    })
  }
}
