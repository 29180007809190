import React, { useState } from 'react'
import { Layout } from '../../Layout'
import { Input, Button } from 'antd'
import * as S from './Bandwidth.styles'
import { Formik } from 'formik'
import { initialValues, validationSchema } from '../EditQuota/EditQuota.config'

export const BandwidthPage = ({
  BandwidthAction,
  CurrentBandwidthAction,
  editBandwidthLoading,
  hostName,
  domainName,
  currentBandwidthData,
}) => {
  const [mb, setMb] = useState(currentBandwidthData)
  return (
    <Layout>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleChange }) => {
          return (
            <S.WebsiteCheckConatiner>
              <S.InputWrapper>
                <S.StyledInput
                  className="Checked"
                  placeholder=" Domain Name"
                  name="domain"
                  value={domainName}
                  disabled
                  onChange={handleChange}
                />

                <S.StyledInput
                  className="Checked"
                  placeholder="State"
                  disabled
                  name="megabytes"
                  defaultValue={currentBandwidthData && currentBandwidthData[0]}
                  onChange={(e) => setMb(e.target.value)}
                />

                <S.StyledInput
                  placeholder="Limit"
                  name="megabytes"
                  defaultValue={currentBandwidthData && currentBandwidthData[1]}
                  onChange={(e) => setMb(e.target.value)}
                />
              </S.InputWrapper>

              <S.ButtonsContainer>
                <Button
                  className="button"
                  loading={editBandwidthLoading}
                  onClick={() =>
                    CurrentBandwidthAction(domainName, `ext${hostName}.host.am`)
                  }
                >
                  Check
                </Button>

                <Button
                  className="buttonAdd"
                  loading={editBandwidthLoading}
                  onClick={() =>
                    BandwidthAction(mb, `ext${hostName}.host.am`, domainName)
                  }
                >
                  Edit
                </Button>
              </S.ButtonsContainer>
            </S.WebsiteCheckConatiner>
          )
        }}
      </Formik>
    </Layout>
  )
}
