import styled from 'styled-components'
import { Input } from 'antd'

export const BackUpPageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
  gap: 20px;
  flex-direction: column;
`

export const StyledInput = styled.input`
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  border-radius: 2;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  width: 120px;
  outline: none;
  @media (min-width: 768px) {
    width: 250px;
  }
  @media (min-width: 1280px) {
    width: 250px;
  }
  :hover {
    border: 1px solid #1890ff;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const ButtonContainer = styled.div`
  display: flex;
`
