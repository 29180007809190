export const extOptions = [
  {
    id: 1,
    value: '40',
    label: 'ext40.host.am',
  },
  {
    id: 2,
    value: '41',
    label: 'ext41.host.am',
  },
  {
    id: 3,
    value: '42',
    label: 'ext42.host.am',
  },
  {
    id: 4,
    value: '43',
    label: 'ext43.host.am',
  },
  {
    id: 5,
    value: '44',
    label: 'ext44.host.am',
  },
  {
    id: 6,
    value: '45',
    label: 'ext45.host.am',
  },
  {
    id: 7,
    value: '46',
    label: 'ext46.host.am',
  },
  {
    id: 8,
    value: '47',
    label: 'ext47.host.am',
  },
  {
    id: 9,
    value: '48',
    label: 'ext48.host.am',
  },
]
