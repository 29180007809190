import { IP_PAGE_TYPES } from './IpPage.types'
const initialState = {
  ipCheckData: null,
  ipCheckLoading: false,
  ipCheckError: null,
  ipUnblockData: null,
  ipUnblockLoading: false,
  ipUnblockError: null,
  rateLimitData:null,
  rateLimithError:null,
  rateLimitLoading:false,

}
export const ipPage = (state = initialState, action) => {
  switch (action.type) {
    case IP_PAGE_TYPES.IP_CHECK_REQUEST:
      return {
        ...state,
        ipCheckLoading: true,
      }
    case IP_PAGE_TYPES.IP_CHECK_SUCCESS:
      return {
        ...state,
        ipCheckLoading: false,
        ipCheckData: action.payload,
      }
    case IP_PAGE_TYPES.IP_CHECK_FAILED:
      return {
        ...state,
        ipCheckLoading: false,
        ipCheckError: action.error,
      }
    case IP_PAGE_TYPES.IP_UNBLOCK_REQUEST:
      return {
        ...state,
        ipUnblockLoading: true,
      }
    case IP_PAGE_TYPES.IP_UNBLOCK_SUCCESS:
      return {
        ...state,
        ipUnblockLoading: false,
        ipUnblockData: action.payload,
      }
    case IP_PAGE_TYPES.IP_UNBLOCK_FAILED:
      return {
        ...state,
        ipUnblockLoading: false,
        ipUnblockError: action.error,
      }
      case IP_PAGE_TYPES.RATE_LIMIT_REQUEST:
      return {
        ...state,
        rateLimitLoading:true
        
      }
    case IP_PAGE_TYPES.RATE_LIMIT_SUCCES:
      return {
        ...state,
        rateLimitLoading: false,
        rateLimitData: action.payload,
      }
    case IP_PAGE_TYPES.RATE_LIMIT_FAIL:
      return {
        ...state,
        rateLimitLoading: false,
        rateLimitError: action.error,
      }
    default:
      return state
  }
}
