import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import { AddNewUser } from '../pages/AddNewUser'
import { EmailCheckPage } from '../pages/EmailCheckPage'
import { IpCheckByEmail } from '../pages/IpCheckByEmail'
import { IpPage } from '../pages/IpPage'
import { LoginPage } from '../pages/LoginPage'
import { MainPage } from '../pages/MainPage/MainPage'
import { WebsiteCheckPage } from '../pages/WebsiteCheckPage'
import { WelcomePage } from '../pages/WelcomePage/WelcomePage'
import { EmailConfiguration } from '../pages/EmailConfiguration'
import { OpenSite } from '../pages/OpenSite'
import { CloseSitePage } from '../pages/CloseSitePage'
import { ProtectedRoute } from './components/ProtectedRoute'
import { QRCodePage } from '../pages/QRCodePage'
import { VerifyPage } from '../pages/Verify'
import { EditQuota } from '../pages/EditQuota'
import { EditBandwidth } from '../pages/Bandwidth'
import { BackUp } from '../pages/BackUp'
import { DeleteUserPage } from '../pages/DeleteUserPage/DeleteUserPage'

export const Routes = ({ isAuth }) => {
  return (
    <Router>
      <Switch>
        <ProtectedRoute path="/backup" component={BackUp} />
        <ProtectedRoute path="/ip" component={IpPage} />
        <ProtectedRoute path="/bandwidth" component={EditBandwidth} />
        <ProtectedRoute path="/main" component={MainPage} />
        <ProtectedRoute path="/welcome" component={WelcomePage} />
        <ProtectedRoute path="/email-check" component={EmailCheckPage} />
        <ProtectedRoute path="/website-check" component={WebsiteCheckPage} />
        <ProtectedRoute path="/add-new-user" component={AddNewUser} />
        <ProtectedRoute path="/ip-check-by-email" component={IpCheckByEmail} />
        <ProtectedRoute path="/edit-quota" component={EditQuota} />
        <ProtectedRoute path="/email-config" component={EmailConfiguration} />
        <ProtectedRoute path="/close-site" component={CloseSitePage} />
        <ProtectedRoute path="/open-site" component={OpenSite} />
        <ProtectedRoute path="/delete-user" component={DeleteUserPage} />
        <Route path="/qr-code" component={QRCodePage} />
        <Route path="/verify" component={VerifyPage} />
        <Route path="/login" component={LoginPage} />
        <Redirect from="/" to={isAuth ? '/main' : '/login'} exact />
        <ProtectedRoute path="*" component={MainPage} />
      </Switch>
    </Router>
  )
}
