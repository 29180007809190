import { connect } from 'react-redux'
import { BackUpPage as Self } from './BackUp'
import { hetznerCheck } from './BackUp.actions'
import { setLoading } from '../../Layout/Layout.actions'
import { setHostName } from '../../../common/user/user.actions'
import { restoreCheck } from './BackUp.actions'

const mapStateToProps = ({ state, hetznerCheckReducer, checkRestore }) => ({
  hostName: state?.user?.hostName,
  backupData: hetznerCheckReducer.backupData,
  backupLoading: hetznerCheckReducer.backupLoading,
  restoreData: checkRestore?.restoreData,
  restoreLoading: checkRestore?.restoreLoading,
})

const mapDispatchToProps = {
  hetznerCheck,
  setLoading,
  setHostName,
  restoreCheck,
}

export const BackUp = connect(mapStateToProps, mapDispatchToProps)(Self)
