import React, { useState, useRef, useEffect } from 'react'
import * as S from './Layout.styles'
import { LeftSideBar } from './components/LeftSideBar'
import { Layout as AntLayout, message } from 'antd'
import { Spin, Dropdown } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { extOptions } from '../pages/BackUp/BackUp.config'

const { Option } = Select

const { Content } = AntLayout

export const Layout = ({
  children,
  loading,
  getHostName,
  setDomainName,
  hostName,
  setHostName,
  getUserData,
  token,
}) => {
  const [fieldValue, setFieldValue] = useState('')

  useEffect(() => {
    getUserData()
  }, [token])

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50, color: '#fff' }} spin />
  )

  return (
    <S.LayoutContainer>
      {loading && (
        <S.Loader>
          <Spin indicator={antIcon} />
        </S.Loader>
      )}

      <S.Header>
        <S.SearchIcon onClick={() => getHostName(fieldValue)} />
        <S.StyledInput
          placeholder="Domain"
          className="search"
          type="text"
          onChange={(e) => {
            setDomainName(e.target.value)
            setFieldValue(e.target.value)
          }}
        />
        <Select
          defaultValue={'ext40.host.am'}
          value={hostName}
          style={{ width: 220 }}
          onChange={(e) => setHostName(e)}
        >
          {extOptions.map((item) => {
            return (
              <Option key={item.id} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
      </S.Header>
      <LeftSideBar />
      <S.StyledContent>{children}</S.StyledContent>
    </S.LayoutContainer>
  )
}
