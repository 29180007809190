import { ROLES } from '../../../../constants/roles'

export const items = [
  {
    id: 1,
    name: 'Welcome',
    path: '/welcome',
  },
  {
    id: 2,
    name: 'IP check/unblock',
    path: '/ip',
    roles: [ROLES.USER, ROLES.MODERATOR, ROLES.ADMIN],
  },
  {
    id: 3,
    name: 'Email check',
    path: '/email-check',
    roles: [ROLES.USER, ROLES.MODERATOR, ROLES.ADMIN],
  },
  {
    id: 4,
    name: 'Website check',
    path: '/website-check',
    roles: [ROLES.USER, ROLES.MODERATOR, ROLES.ADMIN],
  },

  {
    id: 5,
    name: 'IP check by Email',
    path: '/ip-check-by-email',
    roles: [ROLES.USER, ROLES.MODERATOR, ROLES.ADMIN],
  },
  {
    id: 6,
    name: 'Edit quota',
    path: '/edit-quota',
    roles: [ROLES.MODERATOR, ROLES.ADMIN],
  },
  {
    id: 7,
    name: 'Email configuration',
    path: '/email-config',
    roles: [ROLES.USER, ROLES.MODERATOR, ROLES.ADMIN],
  },
  {
    id: 8,
    name: 'Close site (hacked)',
    path: '/close-site',
    roles: [ROLES.ADMIN, ROLES.MODERATOR],
  },
  {
    id: 9,
    name: 'Open site (hacked)',
    path: '/open-site',
    roles: [ROLES.ADMIN, ROLES.MODERATOR],
  },
  {
    id: 10,
    name: 'Add new user',
    path: '/add-new-user',
    roles: [ROLES.ADMIN, ROLES.MODERATOR],
  },
  {
    id: 11,
    name: 'Bandwidth',
    path: '/bandwidth',
    roles: [ROLES.MODERATOR, ROLES.ADMIN],
  },
  {
    id: 12,
    name: 'BackUp',
    path: '/backup',
    roles: [ROLES.ADMIN, ROLES.MODERATOR],
  },
  {
    id: 13,
    name: 'Delete user',
    path: '/delete-user',
    roles: [ROLES.ADMIN, ROLES.MODERATOR],
  },
]
