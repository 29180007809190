import React from 'react'
import { Layout } from '../../Layout'
import * as S from './DeleteUserPage.styles'
import { Select, Button } from 'antd'

import { deleteUser, updateUser } from './DeleteUserPage.actions'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from '../../../common/user/user.actions'
import { useEffect, useState } from 'react'

export const DeleteUserPage = () => {
  const [role, setRole] = useState()
  const { Option } = Select

  const readableRoles = {
    0: 'User',
    1: 'Moderator',
    2: 'Admin',
  }
  const getUsersData = useSelector((state) => state.user.getUsersData)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUsers())
  }, [])
  const handleDelete = (email) => {
    dispatch(
      deleteUser({ email }, () => {
        dispatch(getUsers())
        alert('Deleted')
      })
    )
  }

  const deleteUserError = useSelector(
    (state) => state.deleteUserReducer.deleteUserError
  )
  return (
    <Layout>
      <S.MainDiv>
        <S.Table>
          <S.Tbody>
            {getUsersData.map((user) => (
              <S.Tr>
                <S.Td>{user.email}</S.Td>
                <S.Td>{readableRoles[user.role]}</S.Td>
                <S.Td>
                  <S.DeleteButton
                    type="button"
                    onClick={() => handleDelete(user.email)}
                  >
                    Delete
                  </S.DeleteButton>
                </S.Td>
                <S.Td>
                  <Select
                    onChange={(e) => {
                      dispatch(
                        updateUser({ role: e }, user.email, () => {
                          dispatch(getUsers())
                        })
                      )
                    }}
                    // defaultValue={readableRoles[user.role]}
                    placeholder="Change"
                    style={{ width: 120 }}
                  >
                    <Option value={0}>User</Option>

                    <Option value={1}>Moderator</Option>
                    <Option value={2}>Admin</Option>
                  </Select>
                </S.Td>
              </S.Tr>
            ))}
          </S.Tbody>
        </S.Table>
      </S.MainDiv>
    </Layout>
  )
}
