import { BANDWIDTH_TYPES } from './Bandwidth.types'
const initialState = {
  editBandwidthData: null,
  editBandwidthLoading: false,
  editBandwidthError: null,
  currentBandwidthData: null,
  currentBandwidthLoading: false,
  currentBandwidthError: null,
}
export const editBandwidth = (state = initialState, action) => {
  switch (action.type) {
    case BANDWIDTH_TYPES.BANDWIDTH_REQUEST:
      return {
        ...state,
        editBandwidthLoading: true,
      }
    case BANDWIDTH_TYPES.BANDWIDTH_SUCCESS:
      return {
        ...state,
        editBandwidthLoading: false,
        editBandwidthData: action.payload,
      }
    case BANDWIDTH_TYPES.BANDWIDTH_FAILED:
      return {
        ...state,
        editBandwidthError: action.error,
      }
    default:
      return state
  }
}
export const currentBandwidth = (state = initialState, action) => {
  switch (action.type) {
    case BANDWIDTH_TYPES.CURRENT_BANDWIDTH_REQUEST:
      return {
        ...state,
        currentBandwidthLoading: true,
      }
    case BANDWIDTH_TYPES.CURRENT_BANDWIDTH_SUCCESS:
      return {
        ...state,
        currentBandwidthLoading: false,
        currentBandwidthData: action.payload,
      }
    case BANDWIDTH_TYPES.CURRENT_BANDWIDTH_FAILED:
      return {
        ...state,
        currentBandwidthError: action.error,
        currentBandwidthLoading: false,
      }
    default:
      return state
  }
}
