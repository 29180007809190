import { setDomainName } from '../../../common/user/user.actions'
import { FetchApi } from '../../../utils/FetchApi'
import { BANDWIDTH_TYPES } from './Bandwidth.types'

export const BandwidthAction =
  (limit, hostName, domainName) => async (dispatch) => {
    try {
      dispatch({
        type: BANDWIDTH_TYPES.BANDWIDTH_REQUEST,
      })

      const { data } = await FetchApi.post('commands', {
        command: 'updateBandwidth',
        arg1: domainName,
        arg2: limit,
        host: hostName,
      })
      dispatch({
        type: BANDWIDTH_TYPES.BANDWIDTH_SUCCESS,
        payload: data,
      })
    } catch (e) {
      console.log(e)
      dispatch({
        type: BANDWIDTH_TYPES.BANDWIDTH_FAILED,
        payload: e,
      })
    }
  }
export const CurrentBandwidthAction = (value, hostName) => async (dispatch) => {
  try {
    dispatch({
      type: BANDWIDTH_TYPES.CURRENT_BANDWIDTH_REQUEST,
    })
    const { data } = await FetchApi.post('commands/', {
      command: 'checkBandwidth',
      arg1: value,
      host: hostName,
    })
    dispatch({
      type: BANDWIDTH_TYPES.CURRENT_BANDWIDTH_SUCCESS,
      payload: data.rawData.split('\n'),
      host: hostName,
    })
  } catch (e) {
    console.log(e)
    dispatch({
      type: BANDWIDTH_TYPES.CURRENT_BANDWIDTH_FAILED,
      payload: e,
    })
  }
}
