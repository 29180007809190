import styled from 'styled-components'
import { Form } from 'formik'

export const LoginPageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100%;
`
export const StyledForm = styled(Form)`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`

export const SignInContainer = styled.div`
  font-size: 25px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const ErrorMessageDiv = styled.div`
  display: flex;
  justify-content: center;
  color: red;
`
export const ErrorMessage = styled.p`
  display: flex;
  justify-content: center;
  color: red;
`
