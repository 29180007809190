import { FetchApi } from '../../../utils/FetchApi'
import { OPEN_SITE } from './OpenSite.types'

export const OpenSiteAction = (values, hostName) => async (dispatch) => {
  try {
    console.log('555')
    dispatch({
      type: OPEN_SITE.OPEN_SITE_REQUEST,
    })
    const openSite = await FetchApi.post('commands', {
      command: 'openSite',
      arg1: values.domain,
      host: hostName,
    })
    dispatch({
      type: OPEN_SITE.OPEN_SITE_SUCCESS,
      payload: openSite,
    })
  } catch (e) {
    console.log(e)
    dispatch({
      type: OPEN_SITE.OPEN_SITE_FAILED,
      payload: e,
    })
  }
}
