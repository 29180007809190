import * as yup from 'yup'

export const initialValues = {
  email: '',
  password: '',
  role: 0,
}

export const validationSchema = yup.object().shape({
  email: yup.string().email('Wrong email format').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .test(
      'passwordCheck',
      'Password must contain at least 8 characters',
      (password) => !(password?.length <= 8)
    ),
})
