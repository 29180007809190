import { connect } from 'react-redux'
import { CloseSitePage as Self } from './CloseSitePage'
import { setLoading } from '../../Layout/Layout.actions'
import { CloseSite } from './CloseSitePage.actions'

const mapStateToProps = (state,closeSitePage) => ({
  closeSiteLoading:closeSitePage.closeSiteLoading,
  closSitePageError:closeSitePage.closSitePageError,
  hostName: state.user.hostName,
})

const mapDispatchToProps = {
  setLoading,
  CloseSite,
}

export const CloseSitePage = connect(mapStateToProps, mapDispatchToProps)(Self)
