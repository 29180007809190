import { Button, Input } from 'antd'
import React, { useState } from 'react'
import { Layout } from '../../Layout'
import * as S from './IpCheckByEmail.styles'
import { Formik, isFunction } from 'formik'
import {
  initialValues,
  validationSchema,
} from '../EmailCheckPage/EmailCheckPage.config'

export const IpCheckByEmail = ({
  ipCheckByEmailLoading,
  ipCheckByEmailData,
  ipCheck,
  hostName,
}) => {
  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={validationSchema}
      >
        {({ errors, values, handleBlur, handleChange, dirty }) => (
          <div>
            <S.IpCheckByEmailPageConatiner>
              <S.InputWrapper>
                <S.Label>Email</S.Label>
                <S.StyledInput
                  placeholder="pogos.pogosyan@gmail.com"
                  type="email"
                  name="email"
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </S.InputWrapper>
              {errors.email && <p className="error">{errors.email}</p>}
              <Button
                htmlType="submit"
                disabled={errors.email || !dirty}
                loading={ipCheckByEmailLoading}
                onClick={() => ipCheck(values, `ext${hostName}.host.am`)}
              >
                Check
              </Button>

              {ipCheckByEmailData &&
                !ipCheckByEmailLoading &&
                ({
                  /* <S.CodeArea>{JSON.stringify(ipCheckByEmailData)}
                
                </S.CodeArea> */
                },
                (
                  <div>
                    {ipCheckByEmailData.data.rawData
                      .split('\n')
                      .map((i, index) => {
                        return <div key={index}>{i}</div>
                      })}
                  </div>
                ))}
            </S.IpCheckByEmailPageConatiner>
          </div>
        )}
      </Formik>
    </Layout>
  )
}
