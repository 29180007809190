import styled from 'styled-components'
import { Button } from 'antd'

export const VerifyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
`

export const VerifyButton = styled.button`
  margin-top: 20px;
  cursor: pointer;
  padding: 5px 20px;
  color: #001529;
  border: none;
`

export const VerifyHeader = styled.div`
  font-size: 30px;
  margin-bottom: 30px;
`
