import { connect } from 'react-redux'
import { Routes as Self } from './Routes'
import { FetchApi } from '../../utils/FetchApi'

const mapStateToProps = ({ user }) => ({
  isAuth: !!FetchApi.getToken(),
  token: user.token,
  shouldBeVerified: user.loginData.shouldBeVerified,
})

export const Routes = connect(mapStateToProps)(Self)
