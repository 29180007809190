import React from 'react';
import { Layout } from '../../Layout'


export const MainPage = () => {
  return (
    <Layout>

    </Layout>
  )
}