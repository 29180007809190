import styled from "styled-components";
import { Input } from "antd";

export const WebsiteCheckConatiner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  
`;


export const StyledInput = styled(Input)`
width: 250px;



  @media(min-width: 768px){
    width: 400px;
  }
  @media(min-width: 1280px){
    width: 500px;
  }
`